import React from 'react'
import './about.css'
import ME from '../../assets/im2.jpg'
import {AiOutlineCode} from 'react-icons/ai'
import {AiOutlineFundProjectionScreen} from 'react-icons/ai'

const About = () => {
  return (
    <section id="about" className="about">
      <h4>Get To Know</h4>
      <h1>About Me</h1>
      <div className="container about_container">
        <div className="about_me">
          <div className="about_me-image">
            <img src={ME} alt=""/>
          </div>
        </div>
        <div className="about_content">
          <div className="about_cards">
            <article className="about_card">
              <AiOutlineCode className="about_icon"/>
              <h5>Experience</h5>
              <small>2 months internship</small>
            </article>
            <article className="about_card">
              <AiOutlineFundProjectionScreen className="about_icon"/>
              <h5>Projects</h5>
              <small>10+ Projects</small>
            </article>
          </div>
          <p>
          Hello, my name is George! I am a  junior software engineer from Athens, Greece,  with a passion for developing innovative programs.
          </p>
        </div>
      </div>

      <h2> Skills </h2>

      <div>
        <div className="container knowledge_cards">
          <article className="knowledge_card">
            <h5>Front End Languages</h5>
            <h3>- Javascript</h3>
            <h3>- HTML</h3>
            <h3>- React</h3>
            <h3>- CSS</h3>
          </article>
          <article className="knowledge_card">
            <h5>Back End Languages</h5>
            <h3>- Javascipt</h3>
            <h3>- Node js</h3>
            <h3>- Python</h3>
            <h3>- Java</h3>
          </article>
          <article className="knowledge_card">
            <h5> Databases Languages</h5>
            <h3>- SQL</h3>
            <h3>- MongoDB</h3>
          </article>
        </div>
      </div>

    </section>
  )
}

export default About