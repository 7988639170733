import React from 'react'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {RiMessengerLine} from 'react-icons/ri'
import {AiOutlineLinkedin} from 'react-icons/ai'
import {HiOutlineLocationMarker} from 'react-icons/hi'
import IMG from '../../assets/map.jpg'


const Contact = () => {
  return (
    <section id="contact" className="contact">
      <h4>Get In Touch</h4>
      <h1>Contact Me</h1>

      <div className="container contact_container">
        <article className="contact_option">
          <MdOutlineEmail className="contact_icon"/>
          <h2>Email</h2>
          <h4>georgesamios98@gmail.com</h4>
          <a href="mailto:georgesamios98@gmail.com">Send a message</a>
        </article>
        <article className="contact_option">
          <RiMessengerLine className="contact_icon"/>
          <h2>Messenger</h2>
          <h4>Giorgos Samios</h4>
          <a href="https://m.me/giorgos.samios.9">Send a message</a>
        </article>
        <article className="contact_option">
          <AiOutlineLinkedin className="contact_icon"/>
          <h2>Linkedin</h2>
          <h4>George Samios</h4>
          <a href="https://www.linkedin.com/in/george-samios-2300a6214/">Send a message</a>
        </article>
      </div>
      <article className="location_container">
        <div className="location">
          <HiOutlineLocationMarker className="contact_icon"/>
          <h2>Location</h2>
        </div>
        <img src={IMG} alt=""/>
          <h4>Athens, Greece</h4>
      </article>
    </section>
  )
}

export default Contact