import React from 'react'
import CV from '../../assets/cv.pdf'

const CvTalk = () => {
  return (
    <div className="cvtalk">
        <a href={CV} download className="btn">Download CV</a>
        <a href="#contact" className="btn">Let's Talk</a>
    </div>
  )
}

export default CvTalk