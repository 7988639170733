import React from 'react'
import './portfolio.css'
import IMG from '../../assets/im.jpg'

const Portfolio = () => {
  return (
    <section id="portfolio"  className="portfolio">
      <h4>My Recent Work</h4>
      <h1>Portfolio</h1>
      <div className="container portfolio_container">
        <article className="portfolio_item">
          <div className="portfolio_item-image">
            <img src={IMG} alt=""/>
          </div>
          <h5>myPortfolio</h5>
          <a href='https://github.com/georgesamios98/myPortfolio' className="btn" target="_blank">GitHub</a>
        </article>
        <article className="portfolio_item">
          <div className="portfolio_item-image">
            <img src={IMG} alt=""/>
          </div>
          <h5>Grayscale Video Colorization</h5>
          <a href='https://github.com/georgesamios98/Grayscale-Video-Colorization' className="btn" target="_blank">GitHub</a>
        </article>
        <article className="portfolio_item">
          <div className="portfolio_item-image">
            <img src={IMG} alt=""/>
          </div>
          <h5>Text2Speech Editor</h5>
          <a href='https://github.com/georgesamios98/Text2SpeechEditor' className="btn" target="_blank">GitHub</a>
        </article>
        <article className="portfolio_item">
          <div className="portfolio_item-image">
            <img src={IMG} alt=""/>
          </div>
          <h5>Movie Library</h5>
          <a href='https://github.com/georgesamios98/Movie-Library' className="btn" target="_blank">GitHub</a>
        </article>
        <article className="portfolio_item">
          <div className="portfolio_item-image">
            <img src={IMG} alt=""/>
          </div>
          <h5>Compiler</h5>
          <a href='https://github.com/georgesamios98/Compiler' className="btn" target="_blank">GitHub</a>
        </article>
      </div>
    </section>
  )
}

export default Portfolio