import React from 'react'
import './header.css'
import ME from '../../assets/c6.jpg'
import CvTalk from './CvTalk'
import HeaderSocials from './HeaderSocials'

const Header = () => {
  return (
    <header>
        <div className="container header_container">
            <h4>Hello I'm</h4>
            <h1>George Samios</h1>
            <h5 className="text-light">Entry Level Software Engineer</h5>
            <HeaderSocials/>
            <div className="me">
                <img src={ME} alt="me" />
            </div>
            <CvTalk/>
        </div>
    </header>
  )
}

export default Header