import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'
import {FaFacebookSquare} from 'react-icons/fa'
import {BsInstagram} from 'react-icons/bs'

const HeaderSocials = () => {
  return (
    <div className="header_socials">
        <a href="https://www.linkedin.com/in/george-samios-2300a6214/" target="_blank"><BsLinkedin/></a>
        <a href="https://github.com/georgesamios98" target="_blank"><FaGithub/></a>
        <a href="https://www.facebook.com/giorgos.samios.9" target="_blank"><FaFacebookSquare/></a>
        <a href="https://www.instagram.com/geo_samios/" target="_blank"><BsInstagram/></a>
    </div>
  )
}

export default HeaderSocials