import React from 'react'
import './experience.css'
import {BsFillBriefcaseFill} from 'react-icons/bs'
import {RiFilePaper2Fill} from 'react-icons/ri'
import {MdOutlineLanguage} from 'react-icons/md'

const Experience = () => {
  return (
    <section id="experience" className="experience">
      <h1>My Experience</h1>
      <div className="container experience_container">
        <h4>Experience</h4>
        <article className="experience_details">
          <BsFillBriefcaseFill/>
          <h3>OBERON BUSINESS</h3>
          <h5>as Intership Software Developer</h5>
        </article>
      </div>
      <div className="container experience_container">
        <h4>Studies</h4>
        <article className="experience_details">
          <RiFilePaper2Fill/>
          <h3>Computer Science and Engineering</h3>
          <h5>at University of Ioannina</h5>
        </article>
        <h4>Languages</h4>
        <article className="experience_details">
          <MdOutlineLanguage/>
          <h3>English</h3>
          <h5>Lower Level</h5>
        </article>
      </div>
    </section>
  )
}

export default Experience